import {html, LitElement} from 'lit'
import {customElement, state, property} from 'lit/decorators.js'
import styles from './widget.scss'
import {Theme, Tile} from "../../themes/models";
import {ThemeObserver} from "../../themes/observer";
import {Fader} from "../fader/fader";
import {getTranslationService, ITranslationService, Translator} from "../../services/translation/translation-service";
import {getCustomerGroupService, ICustomerGroupService} from "../../services/group/customer-group";
import {CustomerService, ICustomerService} from "../../services/customer/customer-service";
import {getCountryService, ICountryService} from "../../services/country/country-service";
import {Customer} from "../../services/customer/customer";

@customElement('customer-widget')
export class CustomerWidget extends LitElement {

  @state() customerService: ICustomerService;
  @state() currentCustomer: Customer = null;
  @state() isLoggedIn: boolean = !!this.currentCustomer;
  @state() menuVisible: boolean = false;
  @state() hasError: boolean = false;
  @state() email: string = '';
  @state() password: string = '';
  @state() translator: Translator;
  @state() currentTheme: Theme = Theme.DARK;
  @state() cgs: ICustomerGroupService;
  @state() translationService: ITranslationService;
  @state() countryService: ICountryService;
  @state() saveLogin : boolean = false;
  @property() countryCode: string;

  constructor() {
    super();
    this.customerService = CustomerService.get();
    this.cgs = getCustomerGroupService();
    this.translationService = getTranslationService();
    this.countryService = getCountryService();

    this.onCustomerSubscription = this.onCustomerSubscription.bind(this);
    this.onMenuOpened = this.onMenuOpened.bind(this);
    this.onRequestThemeChange = this.onRequestThemeChange.bind(this);
    this.handleCustomLikesEvent = this.handleCustomLikesEvent.bind(this);

    window.addEventListener('ny-request-theme-change', this.onRequestThemeChange);

    this.customerService.subscribeForCustomer('NY-CUSTOMER-WIDGET', this.onCustomerSubscription)
  }

  async connectedCallback() {
    this.translator = await this.translationService.initTranslations('208671', this.countryService.getCountryCodeFromLocation());
    this.countryCode = this.getAttribute('countryCode') || '';
    window.addEventListener('ny-menu-opened', this.onMenuOpened);
    super.connectedCallback();
  }

  disconnectedCallback() {
    window.removeEventListener('ny-menu-opened', this.onMenuOpened);
    window.removeEventListener('ny-request-theme-change', this.onRequestThemeChange);
    super.disconnectedCallback();
  }

  onMenuOpened(event) {
    const menu = event.detail.menu;
    if (!(menu instanceof CustomerWidget) && this.menuVisible) {
      this.closeMenu();
    }
  }

  onRequestThemeChange(event: CustomEvent) {
    if (event.detail.caller instanceof ThemeObserver || event.detail.caller instanceof CustomerWidget || event.detail.caller instanceof Fader) {
      this.currentTheme = event.detail.theme as Theme;

      if (event.detail.caller instanceof ThemeObserver) {
        if (event.detail.tile === Tile.LEFT) {
          this.currentTheme = Theme.DARK;
        }
      }
      document.querySelector('.separator').setAttribute('class', `separator ${this.currentTheme}`);
    }
  }

  onCustomerSubscription(c: Customer) {
    this.currentCustomer = c;
    this.isLoggedIn = !!this.currentCustomer;
  }

  onWidgetClick() {
    if (!this.menuVisible) {
      window.dispatchEvent(new CustomEvent("ny-menu-opened", {detail: {menu: this as CustomerWidget}}));
    }
    this.menuVisible = !this.menuVisible;
    this.toggleScroll();
  }

  handleCustomLikesEvent() {
    window.dispatchEvent(new CustomEvent("ny-products-client", {detail: {route: 'FAVORITES'}}));
    this.closeMenu();
  }

  toggleScroll() {
    if (this.menuVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'initial';
    }
  }

  onEmailChanged(e) {
    this.email = e.target.value;
  }

  onPasswordChanged(e) {
    this.password = e.target.value;
  }

  onSaveLoginChanged(e) {
    this.saveLogin = e.target.checked;
  }

  resetForm() {
    this.email = '';
    this.password = '';
  }

  async handleEnterPressed(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      await this.login(this.email, this.password, this.saveLogin)
    }
  }

  async login(email: string, password: string, permanent: boolean) {
    this.hasError = false;
    try {
      await this.customerService.login(this.email, this.password, permanent);
      this.closeMenu();
      this.resetForm();
    } catch (e) {
      this.hasError = true;
    }
  }

  async logout() {
    this.hasError = false;
    try {
      await this.customerService.logout();
      this.closeMenu();
      this.resetForm();
    } catch (e) {
      this.hasError = true;
    }
  }

  closeMenu() {
    this.menuVisible = false;
    this.toggleScroll();
  }

  render() {
    if (this.isLoggedIn) {
      return html`
        <style>${styles}</style>
        ${this.menuVisible ? html`
          <div class="closable-overlay" @click="${this.closeMenu}"></div>` : null}
        <div class="customer-widget" @click="${this.onWidgetClick}">
          ${this.menuVisible ? html`
            <svg class="grey">
              <use xlink:href="/img/icons/sprites.svg#times-circle"/>
            </svg>` : html`
            <svg class="red">
              <use xlink:href="/img/icons/sprites.svg#user-filled"/>
            </svg>`}
        </div>
        <div class="customer-menu ${this.menuVisible ? 'active' : ''}">
          <div class="email-spacer">
            <span>${this.currentCustomer.email}</span>
          </div>
          <div class="menu-items">
            <a href="${this.countryCode}/customer-profile/#/private/customer-profile/change-user-data">
              <img src="/img/icons/menu-profile.svg" alt="${this.translator.translate('widget.menu.profile')}"/>
              <span>${this.translator.translate('widget.menu.profile')}</span>
            </a>
            ${this.countryService.getCountryCodeFromLocation() === 'de' ?
              html`<a href="${this.countryCode}/get-more-club/">
                <img src="/img/icons/menu-get-more.svg"
                     alt="${this.translator.translate('widget.menu.get-more-club')}"/>
                <span>${this.translator.translate('widget.menu.get-more-club')}</span>
              </a>` : null}
            <a href="${this.countryCode}/products/?gender=${this.cgs.getCustomerGroup()}&likes=like"
               @click="${this.handleCustomLikesEvent}">
              <img src="/img/icons/menu-favorites.svg" alt="${this.translator.translate('widget.menu.favorites')}"/>
              <span>${this.translator.translate('widget.menu.favorites')}</span>
            </a>
            <button @click="${this.logout}">
              <img src="/img/icons/menu-logout.svg" alt="${this.translator.translate('widget.menu.logout')}"/>
              <span>${this.translator.translate('widget.menu.logout')}</span>
            </button>
          </div>
        </div>
      `
    } else {
      return html`
        <style>${styles}</style>
        ${this.menuVisible ? html`
          <div class="closable-overlay" @click="${this.closeMenu}"></div>` : null}
        <div class="customer-widget" @click="${this.onWidgetClick}">
          ${this.menuVisible ? html`
            <svg class="grey">
              <use xlink:href="/img/icons/sprites.svg#times-circle"/>
            </svg>` : html`
            <svg class="${this.currentTheme}">
              <use xlink:href="/img/icons/sprites.svg#user-circle"/>
            </svg>`}
        </div>
        <div class="customer-menu no-login ${this.menuVisible ? 'active' : ''}">
          <div class="error-container ${this.hasError ? 'has-error' : ''}">
            <div class="error-icon">
              <img src="/img/icons/exclamation-circle.svg" alt="Error Icon"/>
            </div>
            <div class="error-text">
              ${this.translator.translate('widget.login.error')}
            </div>
          </div>

          <div class="email-input">
            <input type="email" value="${this.email}" @input="${this.onEmailChanged}"
                   @keyup="${this.handleEnterPressed}"
                   placeholder="${this.translator.translate('widget.login.placeholder.email')}">
          </div>
          <div class="password-input">
            <input type="password" value="${this.password}" @input="${this.onPasswordChanged}"
                   @keyup="${this.handleEnterPressed}"
                   placeholder="${this.translator.translate('widget.login.placeholder.password')}">
          </div>
          <div class="stay-logged-reset-password-container">
            <div class="save-login">
                <input @click="${this.onSaveLoginChanged}" id="customer-widget-checkbox" name="customer-widget-checkbox" type="checkbox"/>
                <label class="customer-widget-checkbox-label" for="customer-widget-checkbox">${this.translator.translate('widget.login.save-login')}</label>
            </div>
            <div class="password-reset">
                <a href="${this.countryCode}/customer-profile/#/public/customer-profile/reset-password/${this.email && `?email=${this.email}`}">${this.translator.translate('widget.login.button.password-reset')}</a>
            </div>
          </div>
          <div class="buttons">
            ${this.countryService.getCountryCodeFromLocation() === 'de' ?
              html`<a class="button-register"
                      href="/get-more-club/#/register">${this.translator.translate('widget.login.button.register')}</a>` :
              html`<a class="button-register"
                      href="${this.countryCode}/customer-profile/#/public/customer-profile/register-user/customer-profile/noData">${this.translator.translate('widget.login.button.register')}</a>`
            }
            <button class="button-login ${!(this.email && this.password) ? 'disabled' : ''}"
                    @click="${() => this.login(this.email, this.password, this.saveLogin)}">
              ${this.translator.translate('widget.login.button.login')}
            </button>
          </div>
        </div>
      `
    }
  }
}
