import styles from './fader.scss';
import {FaderItem} from "./fader-item";
import {themeObserver} from "../../themes/observer";

export interface IFader extends HTMLElement {
  getActiveItem(): FaderItem;
}

export class Fader extends HTMLElement implements IFader {
  private root: ShadowRoot;
  private timer: NodeJS.Timeout;
  private currentIndex: number;
  private FADER_TIMER: number;
  private allItems: FaderItem[];
  private activeItem: FaderItem;

  constructor() {
    super();
    this.currentIndex = 0;
    this.FADER_TIMER = 5000;
    this.timerTicker = this.timerTicker.bind(this);
    this.itemAdded = this.itemAdded.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.playOnReady = this.playOnReady.bind(this);
    window.addEventListener('ny-fader-item-added', this.itemAdded);
  }

  connectedCallback() {
    this.root = this.attachShadow({mode: 'open'});
    this.root.innerHTML =
      `
      <style>${styles}</style>
      <div id="fader">
      <slot></slot>
      </div>
      `
  }

  disconnectedCallback() {
    clearInterval(this.timer);
    window.removeEventListener('ny-fader-item-added', this.itemAdded);
  }

  static get() {
    return document.querySelector('item-fader') as Fader;
  }

  itemAdded() {
    this.allItems = Array.from(this.querySelectorAll<FaderItem>('fader-item'));
    setTimeout(() => {
      this.setNextItem(this.currentIndex);
      themeObserver.setTheme(this, themeObserver.getThemeOf(this.activeItem));
      if (this.activeItem.isVideo()) {
        this.playOnReady();
      } else if (this.activeItem.isImage()) {
        this.setSourceOnReady();
      }
    })
  }

  playOnReady() {
    const video = this.activeItem?.getVideo();
    if (video) {
      if (video.readyState <= 3) {
        this.stopTimer();
        video.onloadedmetadata = video.play
        video.oncanplaythrough = this.startTimer
        video.load();
      } else {
        video.play();
      }
    }
  }

  setSourceOnReady() {
    const picture = this.activeItem?.getPicture();
    if (picture) {
      picture.childNodes.forEach((source: HTMLElement) => {
        if (source instanceof HTMLImageElement) {
          const image = source as HTMLImageElement;
          image.onload = this.startTimer
          if (!image.complete) {
            this.stopTimer();
          }
        }
        for (const srcAtt of Array.from(source.attributes)) {
          if (srcAtt.name.startsWith('data')) {
            const destAtt = srcAtt.name.substr(srcAtt.name.lastIndexOf('-') + 1, srcAtt.name.length - 1);
            if (!source.getAttribute(destAtt)) {
              source.setAttribute(destAtt, source.getAttribute(srcAtt.name));
            }
          }
        }
      })
    }
  }

  getActiveItem() {
    return this.activeItem;
  }

  setNextItem(index: number) {
    for (let i = 0; i < this.allItems.length; i++) {
      if (i < index) this.allItems[i].setPast();
      if (i === index) this.allItems[i].setActive();
      if (i === (index + 1)) this.allItems[i].setNext();
      if (i > index) this.allItems[i].setFuture();
    }
    this.activeItem = this.allItems[index] as FaderItem;
  }

  timerTicker() {
    if (this.activeItem.isVideo()) {
      this.activeItem.getVideo().pause();
    }
    this.currentIndex += 1;
    if (this.currentIndex >= this.allItems.length) {
      this.currentIndex = 0;
    }
    this.setNextItem(this.currentIndex);
    if (this.isInViewport()) {
      themeObserver.setTheme(this, themeObserver.getThemeOf(this.activeItem));
    }

    if (this.activeItem.isVideo()) {
      this.playOnReady();
    }

    if (this.activeItem.isImage()) {
      this.setSourceOnReady();
    }
  }

  isInViewport() {
    return window.scrollY / window.innerHeight < 0.75;
  }

  startTimer() {
    if (!this.timer) {
      this.timer = setInterval(this.timerTicker, this.FADER_TIMER);
    }
  }

  stopTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
}
window.customElements.define('item-fader', Fader);
