import {html, LitElement} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {getCustomerGroupService} from "../../services/group/customer-group";
import styles from './menu-item.scss'
import {getCountryService} from "../../services/country/country-service";
import config from "../../config";

@customElement('menu-item', )
export class MenuItem extends LitElement {

  @property() customerGroup: string;
  @property() href: string;
  @property() event: string;
  @property() target: string;
  @property() isExternal: string;

  @state() storedCustomerGroup: string;
  @state() countryCode: string;

  connectedCallback() {
    super.connectedCallback();
    this.storedCustomerGroup = getCustomerGroupService().getCustomerGroup();
    this.countryCode = getCountryService().getCountryCodeFromUrl();

    // set default customer group
    if (!this.storedCustomerGroup) {
      this.storedCustomerGroup = 'FEMALE'
    }

    // set default target for external links
    if (this.isExternal != "" && this.target == "") {
      this.target = '_blank'
    }

    // add countryCode for internal links
    if (!this.isExternal && this.countryCode !== "" && this.countryCode.toLowerCase() !== config.defaultLang) {
      this.href = `/${this.countryCode}${this.href}`
    }
  }

  dispatch() {
    window.dispatchEvent(new Event(this.event));
  }

  render() {
    if (this.storedCustomerGroup !== "" && this.customerGroup !== "" && this.storedCustomerGroup !== this.customerGroup) {
      return null;
    }

    if (this.event) {
      return html`
      <style>${styles}</style>
      <li><button @click="${this.dispatch}"><slot></slot></button></li>
    `
    }

    return html`
      <style>${styles}</style>
      <li><a href="${this.href}" target="${this.target}"><slot></slot></a></li>
    `
  }

}
