export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  RED = 'red',
  NONE = '',
}

export enum Tile {
  LEFT = 'LEFT_TILE',
  RIGHT = 'RIGHT_TILE',
}
