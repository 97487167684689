class ItemsObserver {
  private observer: IntersectionObserver;

  constructor() {
    this.observer = new IntersectionObserver(this.onObserve, {threshold: 0.01, rootMargin: "50% 0px 50% 0px"})
  }

  onObserve(entries: IntersectionObserverEntry[], self: IntersectionObserver) {
    for (const e of entries) {
      if (e.target instanceof HTMLVideoElement) {
        if(e.isIntersecting && e.target.paused) {
          e.target.onloadedmetadata = e.target.play
          e.target.load();
          self.unobserve(e.target);
        }
      } else if (e.target instanceof HTMLPictureElement) {
        if (e.isIntersecting) {
          const picture = e.target as HTMLPictureElement;
          picture.childNodes.forEach((source: HTMLElement) => {
            for (const srcAtt of Array.from(source.attributes)) {
              if (srcAtt.name.startsWith('data')) {
                const destAtt = srcAtt.name.substr(srcAtt.name.lastIndexOf('-') + 1, srcAtt.name.length - 1);
                source.setAttribute(destAtt, source.getAttribute(srcAtt.name));
              }
            }
          })
          self.unobserve(e.target);
        }
      }
    }
  }

  addObservable(target: HTMLElement) {
    this.observer.observe(target)
  }
}
export const itemsObserver = new ItemsObserver();
