import {Theme, Tile} from "./models";
import {IFader} from "../fashion/fader/fader";

export class ThemeObserver {
  private entries: HTMLElement[];
  private observer: IntersectionObserver;
  private currentTheme: Theme;
  private observerActive: boolean;

  initObservables() {
    this.entries = Array.from(document.querySelectorAll<HTMLElement>('item-fader, [theme]:not(fader-item)'));

    const fader = this.entries.find(e => (e as IFader).getActiveItem);

    if (fader) {
      // firefox has trouble to recognize whether the item-fader is back in view using the intersectionObserver. use this as fallback
      this.useFallback(fader as IFader);
    }
    this.observer = new IntersectionObserver(entries => {
      for (const e of entries) {
        if (e.isIntersecting) {
          this.observerActive = true;
          if ((e.target as IFader).getActiveItem) {
            const fader = e.target as IFader
            this.setTheme(this, this.getThemeOf(fader.getActiveItem()));
          } else if (this.isTile(e.target)) {
            const index = this.indexOf(e.target)
            if ((index % 2)) {
              this.setTheme(this, this.getThemeOf(e.target), Tile.RIGHT)
            } else if (!(index % 2)) {
              this.setTheme(this, this.getThemeOf(e.target), Tile.LEFT)
            }
          }
          else {
            this.setTheme(this, this.getThemeOf(e.target));
          }
        }
        this.observerActive = false;
      }
    }, {threshold: [0.9], rootMargin: '80% 0% 0% 50%'});

    this.entries.forEach(e => this.observer.observe(e))
  }

  useFallback(fader: IFader) {
    document.addEventListener('scroll', () => {
      if (window.scrollY <= window.innerHeight * 0.9 && !this.observerActive) {
        this.setTheme(this, this.getThemeOf(fader.getActiveItem()))
      }
    })
  }

  setTheme(caller: any, theme: Theme, tile?: Tile) {
    this.currentTheme = theme;
    window.dispatchEvent(new CustomEvent('ny-request-theme-change', {detail: {caller: caller, theme: theme, tile: tile}}));
  }

  getThemeOf(element: Element): Theme {
    return element?.getAttribute('theme')?.toLowerCase() as Theme;
  }

  getCurrentTheme() {
    return this.currentTheme;
  }

  isTile(element: Element) {
    return element.classList.contains('tile');
  }

  indexOf(element: Element) {
    return this.entries.filter(e => e.classList.contains('tile'))?.indexOf(element as HTMLElement);
  }
}
export const themeObserver = new ThemeObserver();
