import styles from './item-selector.scss';
import {Country} from "../../services/country/country";
import {getCountryService, ICountryService} from "../../services/country/country-service";

export class ItemSelector extends HTMLElement {
  private selectedCountry: Country;
  private currentCountry: Country;
  private root: ShadowRoot;
  private cs: ICountryService;

  constructor() {
    super();
    this.cs = getCountryService();
    window.addEventListener('ny-countries-loaded', this.onCountriesLoaded.bind(this))
    window.addEventListener('ny-country-selected', this.onCountrySelected.bind(this))
  }

  onCountriesLoaded(event) {
    this.selectedCountry = this.currentCountry || event.detail.countries.find(c => c.ny_iso.toLowerCase() === this.cs.getCountryCodeFromLocation());
    this.render();
  }

  onCountrySelected(event) {
    this.selectedCountry = event.detail.country;
    this.render();
  }

  onCountrySubscription(c: Country) {
    if (c) {
      this.currentCountry = c;
      this.selectedCountry = c;
      this.render();
    }
  }

  isActive() {
    const list = document.querySelector('.country-list');
    return list.classList.contains('active');
  }

  setActive(state: boolean) {
    const list = document.querySelector('.country-list');
    state ? list.classList.add('active') : list.classList.remove('active');
  }

  connectedCallback() {
    this.root = this.attachShadow({mode: 'open'})
    const NY_COUNTRY_PICKER_ITEM_SELECTOR = 'NY_COUNTRY_PICKER_ITEM_SELECTOR'
    this.cs.subscribeForCurrentCountry(NY_COUNTRY_PICKER_ITEM_SELECTOR, this.onCountrySubscription.bind(this))
  }

  disconnectedCallback() {
    window.removeEventListener('ny-countries-loaded', this.onCountriesLoaded.bind(this))
    window.removeEventListener('ny-country-selected', this.onCountrySelected.bind(this))
  }

  render() {
    this.root.innerHTML = `
      <style>${styles}</style>
      <div class="item-selector" role="combobox" aria-haspopup="listbox" aria-expanded="false" aria-labelledby="country-picker-label">
        <div class="selected-element" tabindex="0" role="button" id="country-picker-label">
          ${this.selectedCountry?.name} | ${this.selectedCountry?.localized_name}
          <svg aria-hidden="true">
            <use xlink:href="/img/icons/sprites.svg#chevron-down"/>
          </svg>
        </div>
      </div>
    `;
  }
}

window.customElements.define('item-selector', ItemSelector)
