import {html, LitElement} from "lit";
import {customElement, state} from "lit/decorators.js";
import style from './country-switcher-confirmation-dialog.scss';
import {getCustomerService, ICustomerService} from "../../services/customer/customer-service";
import {getCountryService, ICountryService} from "../../services/country/country-service";
import {Country} from "../../services/country/country";
import {Customer} from "../../services/customer/customer";
import {getTranslationService, ITranslationService, Translator} from "../../services/translation/translation-service";

export const NY_COUNTRY_SWITCHER_DIALOG_KEY = 'NY_COUNTRY_SWITCHER_CONFIRMATION_DIALOG';

@customElement('country-switcher-confirmation-dialog')
export class CountrySwitcherConfirmationDialog extends LitElement {

  @state() countryService: ICountryService;
  @state() currentCountry: Country;
  @state() newCountry: Country;
  @state() customer: Customer;
  private readonly customerService: ICustomerService;
  @state() translator: Translator;
  @state() translationService: ITranslationService;


  constructor() {
    super();
    this.countryService = getCountryService();
    this.translationService = getTranslationService();
    this.customerService = getCustomerService();
    this.customerService?.subscribeForCustomer(NY_COUNTRY_SWITCHER_DIALOG_KEY, (customer: Customer) => {
      this.customer = customer;
      if(this.customer){
        this.redirectToCustomerCountry();
      }
    });
    this.countryService.subscribeForCurrentCountry(NY_COUNTRY_SWITCHER_DIALOG_KEY, country => this.currentCountry = country);
    this.handleConfirmationDialogOpen = this.handleConfirmationDialogOpen.bind(this);
  }

  async connectedCallback() {
    this.translator = await this.translationService.initTranslations('208671', this.countryService.getCountryCodeFromLocation());
    super.connectedCallback();
    window.addEventListener('country-switcher-confirmation-dialog:open', this.handleConfirmationDialogOpen);
  }

  handleConfirmationDialogOpen(event: CustomEvent<Country>) {
    this.newCountry = event.detail;
    if (this.customer && this.newCountry.iso_3166.toLowerCase() !== this.customer.countryCode.toLowerCase()) {
      this.showConfirmationDialog();
    } else {
      this.countryService.setCurrentCountry(this.newCountry);
      this.countryService.routeToCountry(this.newCountry.ny_iso.toLowerCase());
    }
  }

  showConfirmationDialog() {
    document.querySelector('country-switcher-confirmation-dialog').classList.add('show');
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = 'hidden';
    document.body.style.height = '100%';
    document.documentElement.style.height = '100%';
  }

  hideConfirmationDialog() {
    document.querySelector('country-switcher-confirmation-dialog').classList.remove('show');
    document.body.removeAttribute('style');
    document.documentElement.removeAttribute('style');
  }

  async handleUserLogout() {
    await this.customerService.logout();
    this.countryService.setCurrentCountry(this.newCountry);
    this.countryService.routeToCountry(this.newCountry.ny_iso.toLowerCase());
  }

  async redirectToCustomerCountry() {
    try {
      const countries = await this.countryService.getCountries();
      const country = countries.find(c => c.iso_3166 === this.customer.countryCode)
      if (country) {
        this.countryService.setCurrentCountry(country);
        const countryCode = this.countryService.getCountryCodeFromLocation();
        if (countryCode.toLowerCase() !== country.ny_iso.toLowerCase()) {
          this.countryService.routeToCountry(this.customer.countryCode.toLowerCase());
        }
      }
    } catch (e) {
      console.log('[country-service] error while loading country: ', e)
    }
  }

  render() {
    return html`
      <style>${style}</style>

      <div class="country-switcher-popup-container" @click="${this.hideConfirmationDialog}">
        <div class="container">
          <div class="close-container">
            <svg id="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                 @click="${this.hideConfirmationDialog}">
              <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </div>
          <div class="confirmation-title">${this.translator.translate('country-switcher-dialog.title')}</div>
          <div class="confirmation-info">${this.translator.translate('country-switcher-dialog.info')}</div>
          <div class="button-container">
            <button class="logout-button" @click="${this.handleUserLogout}">
              ${this.translator.translate('country-switcher-dialog.logout-info')}
            </button>
            <button class="redirect-button" @click="${this.hideConfirmationDialog}">
              ${this.translator.translate('country-switcher-dialog.stay-info')}
            </button>
          </div>
        </div>
      </div>
    `
  }
}
