import {BannerItem} from "../banner/banner-item";
import styles from './fader-item.scss'

export class FaderItem extends BannerItem {
  constructor() {
    super();
    this.setActive = this.setActive.bind(this);
    this.setNext = this.setNext.bind(this);
    this.setFuture = this.setFuture.bind(this);
    this.setPast = this.setPast.bind(this);
    this.isImage = this.isImage.bind(this);
    this.isVideo = this.isVideo.bind(this);
  }

  connectedCallback() {
    this.styles = styles
    super.connectedCallback();
    window.dispatchEvent(new Event('ny-fader-item-added'));
  }

  setActive() {
    const item = this.root.querySelector('.fadeable');
    item.setAttribute('class', `fadeable active`)
  }

  setPast() {
    const item = this.root.querySelector('.fadeable');
    item.setAttribute('class', `fadeable past`)
  }

  setFuture() {
    const item = this.root.querySelector('.fadeable');
    item.setAttribute('class', `fadeable future`)
  }

  setNext() {
    const item = this.root.querySelector('.fadeable');
    item.setAttribute('class', `fadeable future next`)
  }

  getVideo() {
    return this.root.querySelector('video');
  }

  getPicture() {
    return this.root.querySelector('picture');
  }

  isVideo() {
    return this.type === 'video'
  }

  isImage() {
    return this.type === 'image'
  }
}
window.customElements.define('fader-item', FaderItem)
