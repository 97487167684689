import {html, LitElement} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {getCustomerGroupService} from "../../services/group/customer-group";

@customElement('social-icon', )
export class MenuItem extends LitElement {

  @property() customerGroup: string;
  @state() storedCustomerGroup: string;

  connectedCallback() {
    super.connectedCallback();
    this.storedCustomerGroup = getCustomerGroupService().getCustomerGroup();

    // set default customer group
    if (!this.storedCustomerGroup) {
      this.storedCustomerGroup = 'FEMALE'
    }
  }

  render() {
    if (this.storedCustomerGroup !== this.customerGroup && this.customerGroup !== "") {
      return null;
    }

    return html`<li><slot></slot></li>`
  }

}
