import styles from './banner-item.scss';
import {itemsObserver} from "../intersection/observer";

export class BannerItem extends HTMLElement {
  private desktopSrc: string;
  private desktopPreview: string;
  private mobileSrc: string;
  private mobilePreview: string;
  private theme: string;
  private position: string;
  private brand: string;
  private brandSrc: string;
  private actionProducts: string;
  private actionSpecial: string;
  private translationButtonSpecial: string;
  private translationButtonProducts: string;
  private alt: string;
  private externalLinkSpecial: boolean;
  private externalLinkProducts: boolean;
  private langPrefix: string;

  protected type: string;
  protected root: ShadowRoot;
  protected styles = styles

  connectedCallback() {
    this.desktopSrc = this.getAttribute('desktopSrc') || this.desktopSrc;
    this.desktopPreview = this.getAttribute('desktopPreview') || this.desktopPreview;
    this.mobileSrc = this.getAttribute('mobileSrc') || this.mobileSrc;
    this.mobilePreview = this.getAttribute('mobilePreview') || this.mobilePreview;
    this.type = this.getAttribute('type') || this.type;
    this.alt = this.getAttribute('alt') || this.alt;
    this.theme = this.getAttribute('theme').toLowerCase() || this.theme;
    this.position = this.getAttribute('position') || this.position;
    this.brand = this.getAttribute('brand')?.toLowerCase() || this.brand;
    this.brandSrc = this.getAttribute('brandSrc')?.toLowerCase() || this.brandSrc;
    this.actionProducts = this.getAttribute('actionProducts') || this.actionProducts;
    this.actionSpecial = this.getAttribute('actionSpecial') || this.actionSpecial;
    this.translationButtonSpecial = this.getAttribute('translationButtonSpecial') || this.translationButtonSpecial;
    this.translationButtonProducts = this.getAttribute('translationButtonProducts') || this.translationButtonProducts;
    this.externalLinkSpecial = Boolean(this.getAttribute('externalLinkSpecial') || this.externalLinkSpecial);
    this.externalLinkProducts = Boolean(this.getAttribute('externalLinkProducts') || this.externalLinkProducts);
    this.langPrefix = this.getAttribute('langPrefix') || '';

    this.renderItem();
  }

  renderItem() {
    this.root = this.attachShadow({mode: 'open'});
    const style = document.createElement('style');
    style.innerHTML = this.styles;
    const fadeable = document.createElement('a');
    if (this.externalLinkSpecial) {
      fadeable.target = '_blank';
      fadeable.href = this.actionSpecial;
    } else {
      fadeable.href = this.langPrefix + this.actionSpecial;
    }
    fadeable.classList.add('fadeable');
    const fullSizeItem = document.createElement('div');
    fullSizeItem.classList.add('full-size-item')
    fullSizeItem.classList.add(this.theme);

    if (this.type === 'image') {
      fullSizeItem.append(this.renderImage());
    }

    if (this.type === 'video') {
      fullSizeItem.append(this.renderVideo());
    }

    const itemContent = document.createElement('div');
    itemContent.classList.add('item-content');
    itemContent.classList.add('low');
    const div = document.createElement('div');
    itemContent.appendChild(div);

    fullSizeItem.appendChild(itemContent);


    div.appendChild(this.renderBrand());
    div.appendChild(this.renderActions());

    fadeable.appendChild(fullSizeItem);
    this.root.appendChild(style)
    this.root.appendChild(fadeable);
  }

  renderBrand(): HTMLDivElement {
    const itemBrand = document.createElement('div');
    itemBrand.classList.add('item-brand');
    itemBrand.classList.add(this.brand);
    if (this.brand === 'custom') {
      const img = document.createElement('img');
      img.src = this.brandSrc;
      itemBrand.appendChild(img);
    } else {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/img/icons/brands.svg#${this.brand}`);
      svg.appendChild(use);
      itemBrand.appendChild(svg);
    }

    return itemBrand;
  }

  renderActions(): HTMLDivElement {
    const itemActions = document.createElement('div');
    itemActions.classList.add('item-actions');

    if (this.actionSpecial && this.translationButtonSpecial) {
      itemActions.appendChild(this.createButton(this.actionSpecial, this.translationButtonSpecial, this.externalLinkSpecial))
    }
    if (this.actionProducts && this.translationButtonProducts) {
      itemActions.appendChild(this.createButton(this.actionProducts, this.translationButtonProducts, this.externalLinkProducts))
    }

    return itemActions;
  }

  createButton(href: string, innerHTML: string, external: boolean) {
    const link = document.createElement('a');
    link.innerHTML = innerHTML;
    if (external) {
      link.target = '_blank';
      link.href = href;
    } else {
      link.href = this.langPrefix + href;
    }

    return link;
  }

  renderImage(): HTMLPictureElement {
    const picture = document.createElement('picture')
    const mobileSrc = document.createElement('source');
    mobileSrc.media = '(max-width: 1024px)';
    mobileSrc.setAttribute('data-srcset', this.mobileSrc)
    const desktopSrc = document.createElement('source');
    desktopSrc.media = '(min-width: 1024px)';
    desktopSrc.setAttribute('data-srcset', this.desktopSrc)
    picture.appendChild(mobileSrc)
    picture.appendChild(desktopSrc)
    const fallback = document.createElement('img')
    fallback.alt = this.brand;
    fallback.setAttribute('data-src', this.desktopSrc)
    picture.appendChild(fallback)
    itemsObserver.addObservable(picture)
    return picture
  }

  renderVideo(): HTMLVideoElement {
    const video = document.createElement('video');
    video.src = this.getVideoSrc();
    if (this.desktopPreview && this.mobilePreview) {
      video.poster = this.getVideoPreview();
    }
    video.autoplay = false;
    video.preload = 'none';
    video.loop = true;
    video.muted = true;
    video.setAttribute('playsinline', 'true');
    itemsObserver.addObservable(video)
    return video;
  }

  getVideoSrc() {
    if (screen.availWidth < 1024) {
      return this.mobileSrc;
    }
    return this.desktopSrc;
  }

  getVideoPreview() {
    if (screen.availWidth < 1024) {
      return this.mobilePreview;
    }
    return this.desktopPreview;
  }
}
window.customElements.define('banner-item', BannerItem)
