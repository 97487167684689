import styles from './country-picker.scss';
import itemStyle from './item-selector.scss';
import listStyles from './country-list.scss';
import conf from '../../config'
import {getCountryService, ICountryService} from "../../services/country/country-service";
import {Country} from "../../services/country/country";

export class CountryPicker extends HTMLElement {
  private imageKey: string;
  private mobileImageKey: string;
  private cs: ICountryService;
  private currentCountry: Country;
  private active: boolean;
  private countries: Country[];
  private isDropdownOpen: boolean = false;
  private setSelect: boolean;

  constructor() {
    super();
    this.cs = getCountryService();

    window.addEventListener('ny-country-selected', this.onCountryClick.bind(this));
    window.addEventListener('countrySwitcher:toggle', this.onToggleEvent.bind(this))
    window.addEventListener('keydown', this.handleKeyPress.bind(this));

    if (this.getUrlParam('disableCountrySwitcher')) {
      this.render = () => {
        return;
      };
      this.unrender = () => {
        return;
      };
    }
  }

  handleKeyPress = (event: KeyboardEvent) => {
    const {key} = event;
    const openKeys = ['Enter'];
    const closeKeys = ['Tab', 'Escape'];
    const arrowKeys = ['ArrowUp', 'ArrowDown'];

    this.setSelect = document.activeElement.classList.contains('selected-element');

    if (!this.setSelect) {
      return;
    }

    if (this.isDropdownOpen && (closeKeys.includes(key) || arrowKeys.includes(key))) {
      event.preventDefault();
    }

    if (openKeys.includes(key)) {
      this.toggleSelectorState(!this.isDropdownOpen);
      return;
    }

    if (closeKeys.includes(key) && this.isDropdownOpen) {
      this.toggleSelectorState(false);
      return;
    }

    if (arrowKeys.includes(key)) {
      let indexOfCurrentCountry: number = this.countries.findIndex(
        country => this.currentCountry.iso_3166.toLowerCase() === country.iso_3166.toLowerCase()
      );

      if (key === arrowKeys[0] && indexOfCurrentCountry > 0) {
        indexOfCurrentCountry -= 1;
      }

      if (key === arrowKeys[1] && indexOfCurrentCountry < this.countries.length - 1) {
        indexOfCurrentCountry += 1;
      }
      this.currentCountry = this.countries[indexOfCurrentCountry];
      this.render();

      const activeCountryElement = document.querySelector('#active-country');
      if (activeCountryElement) {
        activeCountryElement.scrollIntoView({block: 'nearest', behavior: 'auto'});
      }
    }
  }

  onToggleEvent() {
    this.active ? this.unrender() : this.render();
  }

  onClick(event) {
    if (event.target instanceof HTMLAnchorElement) {
      return;
    }

    if (event.target.classList.contains('selected-element')) {
      this.toggleSelectorState(!this.isDropdownOpen);
    } else {
      this.toggleSelectorState(false);
    }
  }

  toggleSelectorState(state: boolean) {
    const countryPickerElem = document.querySelector('.selected-element');
    const countryListElem = document.querySelector('.list-container');

    if (state) {
      countryPickerElem.setAttribute('aria-expanded', 'true');
      countryListElem.classList.add('active');
      this.isDropdownOpen = true;
    } else {
      countryPickerElem.setAttribute('aria-expanded', 'false');
      countryListElem.classList.remove('active');
      this.isDropdownOpen = false;
    }

    this.querySelectorAll('.country-picker .toggleable')?.forEach(elem => {
      state ? elem.classList.add('hide') : elem.classList.remove('hide');
    })
    this.querySelector('.country-picker')?.classList.toggle('active');
  }

  onCountrySubscription(country: Country) {
    const countryCode = this.cs.getCountryCodeFromLocation();
    if (country) {
      this.currentCountry = country;
    } else {
      if (countryCode === conf.defaultLang) {
        this.render();
      } else {
        const byCountryCode = this.countries.find(c => c.ny_iso.toLowerCase() === countryCode.toLowerCase());
        if (byCountryCode) {
          this.cs.setCurrentCountry(byCountryCode);
        }
      }
    }
  }

  onCountryClick(country: Country) {
    this.currentCountry = country;
    this.toggleSelectorState(false);
    this.render();
  }

  onSaveButtonClick() {
    if (this.currentCountry) {
      this.cs.setCurrentCountry(this.currentCountry);
      this.cs.routeToCountry(this.currentCountry.ny_iso.toLowerCase())
    }
    this.unrender();
  }

  toggleBackgroundElements(state: boolean) {
    const cookieSettings = document.querySelector('cookie-settings');
    const cookieBanner = document.querySelector('cookie-banner');
    const links = document.querySelectorAll('main a');

    if(cookieSettings){
      cookieSettings.setAttribute('aria-hidden', `${state}`);
      state ? cookieSettings.setAttribute('tabIndex', '-1') : cookieSettings.removeAttribute('tabIndex');
    }

    if(cookieBanner){
      cookieBanner.setAttribute('aria-hidden', `${state}`);
      state ? cookieBanner.setAttribute('tabIndex', '-1') : cookieBanner.removeAttribute('tabIndex');
    }

    if(links.length > 0){
      links.forEach(link => {
        link.setAttribute('aria-hidden', `${state}`);
        state ? link.setAttribute('tabIndex', '-1') : link.removeAttribute('tabIndex');
      })
    }
  }

  render() {
    this.countries.sort((a, b) => a.name.localeCompare(b.name));

    if (!this.currentCountry) {
      this.currentCountry = this.countries.find(c => c.iso_3166.toLowerCase() === this.cs.getCountryCodeFromLocation());
    }

    this.toggleBackgroundElements(true);

    const bodyElement = <HTMLBodyElement>document.querySelector('#fashion');
    bodyElement.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    this.active = true;
    this.imageKey = this.getAttribute('imagekey') || this.imageKey;
    this.mobileImageKey = this.getAttribute('mobileimagekey') || this.mobileImageKey;

    this.innerHTML = `
      <style>${styles}</style>
      <style>
        .background-image {
            background: url(${this.imageKey}) no-repeat; background-size: cover;
        }

        @media screen and (max-width: 1024px) {
          .background-image {
            background: url(${this.mobileImageKey}) no-repeat; background-size: cover;
          }
        }

      </style>
      <div class="background-image"></div>
      <div class="country-picker-container">
        <div class="overlay" tabindex="-1">
          <div class="country-picker">
            <div class="image-container toggleable">
              <svg aria-hidden="true">
                <use xlink:href="/img/icons/sprites.svg#ny-logo"/>
              </svg>
            </div>

            <div class="item-selector-container">
                <style>${itemStyle}</style>
                <div class="selected-element"
                  tabindex="0"
                  role="button"
                  id="country-picker-label"
                  aria-controls="country-list-scroll-container"
                  role="combobox"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  aria-live="polite"
                  aria-label="country-picker"
                >
                  ${this.currentCountry?.name} | ${this.currentCountry?.localized_name}
                  <svg aria-hidden="true">
                    <use xlink:href="/img/icons/sprites.svg#chevron-down"/>
                  </svg>
                </div>

                <div class="list-container ${this.isDropdownOpen ? 'active' : ''}">
                  <style>${listStyles}</style>
                  <ul tabindex="-1" class="country-list" role="listbox" aria-label="Country selection list">
                    ${this.countries.map(country => `
                      <li
                        role="option"
                        class="country ${this.checkSelected(country) ? 'active' : ''}"
                        aria-selected="${this.checkSelected(country)}"
                        aria-label="${country.name} | ${country.localized_name}"
                        tabindex="-1"
                        id="${this.checkSelected(country) ? 'active-country' : ''}"
                      >
                        ${country.name} | ${country.localized_name}
                        ${this.checkSelected(country) ? '<img aria-hidden="true" src="/img/icons/check.svg" alt="Checkmark" />' : ''}
                      </li>
                    `).join('')}
                  </ul>
              </div>
            </div>

            <div class="button-container toggleable">
              <button>GO</button>
            </div>
            <div class="legal-container toggleable">
              <a href="/legal/imprint?disableCountrySwitcher=true">Impressum</a>
              <a href="/legal/privacy?disableCountrySwitcher=true">Datenschutz</a>
            </div>
          </div>
        </div>
      </div>`


    const selectedElement = <HTMLDivElement>document.querySelector('.selected-element');
    this.setSelect && selectedElement.focus();
    selectedElement.addEventListener('click', () => this.toggleSelectorState(this.isDropdownOpen));
    selectedElement.addEventListener('click', (e) => this.onClick(e))

    this.querySelector('.country-picker .button-container button').addEventListener('click', this.onSaveButtonClick.bind(this));
    this.querySelectorAll('.country-list .country').forEach((ce, i) => ce.addEventListener('click', () => this.onCountryClick(this.countries[i])))
  }

  checkSelected(country: Country): boolean {
    return this.currentCountry.iso_3166.toLowerCase() === country.iso_3166.toLowerCase()
  }

  unrender() {
    const bodyElement = document.querySelector('#fashion') as HTMLElement;
    bodyElement.style.overflow = 'initial';
    document.documentElement.style.overflow = 'initial';
    this.active = false;
    this.innerHTML = null;

    this.toggleBackgroundElements(false);
  }

  getUrlParam(name, url?) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  async connectedCallback() {
    this.countries = await this.cs.getCountries();
    this.currentCountry = this.currentCountry || this.countries[0];
    const NY_COUNTRY_PICKER = 'NY_COUNTRY_PICKER'
    this.cs.subscribeForCurrentCountry(NY_COUNTRY_PICKER, this.onCountrySubscription.bind(this))
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.onClick.bind(this));
    this.querySelector('.country-picker .button-container button').addEventListener('click', this.onSaveButtonClick.bind(this))
    window.removeEventListener('ny-country-selected', this.onCountryClick.bind(this));
    window.removeEventListener('countrySwitcher:toggle', this.onToggleEvent.bind(this))
  }
}

window.customElements.define('country-picker', CountryPicker);
