import styles from './logo.scss';
import {html, LitElement, PropertyValues} from "lit";
import {customElement, state} from "lit/decorators.js";
import {Theme, Tile} from "../../themes/models";
import {ThemeObserver, themeObserver} from "../../themes/observer";
import {Hamburger} from "../hamburger/hamburger";
import {getCustomerGroupService, ICustomerGroupService} from "../../services/group/customer-group";
import {getCountryService, ICountryService} from "../../services/country/country-service";

@customElement('ny-logo')
export class Logo extends LitElement {

  @state() currentTheme: Theme = Theme.RED
  @state() themedElements: HTMLElement[];
  @state() countryCode: string;
  @state() countryService: ICountryService;
  @state() cgs: ICustomerGroupService;

  constructor() {
    super();
    this.onRequestThemeChange = this.onRequestThemeChange.bind(this);
    window.addEventListener('ny-request-theme-change', this.onRequestThemeChange);

    this.countryService = getCountryService();
    this.cgs = getCustomerGroupService();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('ny-request-theme-change', this.onRequestThemeChange);

  }

  onRequestThemeChange(event: CustomEvent) {
    const menu = document.querySelector('hamburger-menu') as Hamburger;
    if (menu.isMenuVisible()) {return}

    this.currentTheme = event.detail.theme as Theme;

    if (event.detail.caller instanceof ThemeObserver) {
      if (event.detail.tile === Tile.RIGHT) {
        this.currentTheme = Theme.DARK;
      }
    }
  }

  protected firstUpdated(_changedProperties:PropertyValues) {
    super.firstUpdated(_changedProperties);
    themeObserver.initObservables();
  }

  render() {
    const customerGroup = this.cgs.getCustomerGroup();
    this.countryCode = this.countryService.getCountryCodeFromLocation();

    let gender = 'women';
    if (customerGroup === 'MALE') {
      gender = 'men';
    }

    let href = gender;
    if (this.countryCode !== 'de') {
      href = `${this.countryCode}/${gender}`;
    }

    return html`
        <style>${styles}</style>
        <a href="/${href}/">
        <svg class="logo ${this.currentTheme}">
            <use xlink:href="/img/icons/sprites.svg#ny-logo"
        </svg>
        </a>
        `
  }
}
